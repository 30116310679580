import toNumber from 'lodash/toNumber';
import range from 'lodash/range';
import numeral from 'numeral';
import { numberFix } from './helper';

function getFormatTemplate({ currency, precision }) {
  if (currency) return `$0,0.${range(precision).join('')}`;

  return `0,0.${range(precision).join('')}`;
}

export function getPercentage(newValue, oldValue, precision = 2) {
  let n = newValue,
    o = oldValue;
  if (n > 0 && n < 1) n = 1;
  if (o > 0 && o < 1) o = 1;
  const newNumber = toNumber(n);
  const oldNumber = toNumber(o);
  const increase = newNumber - oldNumber;

  if (!increase || !oldNumber) {
    return 0;
  }

  return numberFix((increase / Math.abs(oldNumber)) * 100, precision);
}

export function currencyFormat(value = 0) {
  if (value === '0.00' || value === 0) return '$0.00';
  const isNumber =
    numeral(value).format(0, 0.0) !== 'NaN' &&
    numeral(value).format('$0,0.00') !== 'N$aN' &&
    numeral(value).format('$0,0.00') !== '0$.00';
  return isNumber ? numeral(value).format('$0,0.00') : '$0.00';
}

export function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1]?.length || 0;
}

export function convertLetterToNumber(str) {
  let out = 0;
  const len = str.length;

  for (let pos = 0; pos < len; pos++) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
  }

  return out;
}

export function currencyFormatterWithNoValue(value = 0) {
  const tempValue = +value;
  if (!tempValue) return '-';
  return tempValue < 0.01 ? '< $0.01' : currencyFormat(tempValue);
}

export function numberFormatter(value = 0, format = {}) {
  const { currency = false, percentage = false, precision = 2 } = format;

  const decimals = value ? (value.toString().split('.')[1] || '').length : 0;
  const applyPrecision = decimals > precision;
  const formatTmpl = getFormatTemplate({
    currency,
    precision: applyPrecision ? precision : decimals,
  });

  const formattedValue = numeral(value).format(formatTmpl);
  return percentage ? formattedValue + '%' : formattedValue;
}
