import React from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { FilterExpandIcon, FilterCollapseIcon, EmptySvg, SearchIcon, PencilIcon } from 'assets/images';
import { currencyFormat } from 'utils/number';

import { Anchor } from 'components/Custom';
import RenameAWSAccount from '../RenameAWSAccountName';
import { formatCurrencyByCode } from 'utils/currencySymbol';

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: ['account', 'region', 'product', 'vpc', 'cloudPlatform', 'instanceType'],
      search: [],
      searchValue: '',
      showData: [...props.data],
      showModal: false,
      name: '',
      accountData: {},
    };
  }

  componentDidMount() {
    const { isOpen, type } = this.props;
    const { expanded } = this.state;
    if (isOpen) {
      const temp = [...expanded];
      if (!expanded.includes(type)) {
        temp.push(type);
      }
      this.setState({ expanded: temp });
    }
    this.setSearchArray();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isClear, data, searchKeyWord } = this.props;
    if (!isEqual(data, prevProps.data)) {
      this.setState({
        showData: [...data],
      });
    }
    if (isClear && prevProps.isClear !== isClear) {
      this.setState({
        showData: [...data],
      });
    }
    if (prevProps.searchKeyWord !== searchKeyWord) {
      this.setSearchArray();
    }
  }

  setSearchArray = () => {
    const { searchKeyWord = '', type } = this.props;
    const { search } = this.state;
    if (['Usage Type', 'Operations'].includes(type)) {
      const newSearch = search.slice();
      if (!newSearch.includes(type)) {
        newSearch.push(type);
      }
      this.setState({
        search: newSearch,
        searchValue: searchKeyWord,
      });
    }
  };

  onExtendToggle = key => () => {
    const { expanded } = this.state;
    const temp = [...expanded];
    const findIndex = expanded.findIndex(keys => keys === key);
    if (findIndex >= 0) {
      temp.splice(findIndex, 1);
    } else {
      temp.push(key);
    }
    this.setState({ expanded: temp });
  };

  onSearchToggle = key => () => {
    const { search, expanded } = this.state;
    const tempSearch = [...search];
    const tempExpanded = [...expanded];
    const findIndex = search.findIndex(keys => keys === key);
    if (findIndex >= 0) {
      tempSearch.splice(findIndex, 1);
    } else {
      tempSearch.push(key);
      const expandIndex = expanded.findIndex(keys => keys === key);
      if (expandIndex === -1) {
        tempExpanded.push(key);
      }
    }
    this.setState({ search: tempSearch, expanded: tempExpanded });
  };

  onChangeRow = (event, row) => {
    const { dataset: { checkid: checkID = '' } = {} } = event.target;
    this.props.onChange(
      {
        checked: event.target.checked,
        id: checkID,
        value: row.value,
        cloudPlatform: row.cloudPlatform,
      },
      this?.props?.type || '',
    );
  };

  onChange = (event, value) => {
    const { dataset: { checkid: checkID = '' } = {} } = event.target;
    this.props.onChange(
      {
        checked: event.target.checked,
        id: checkID,
        value,
      },
      this?.props?.type || '',
    );
  };

  handleFixPermission = projectID => () => {
    window.open(`/v3/settings?tab=AWS%20Accounts#${projectID}`, '_blank');
  };

  handleFilterSearch = e => {
    const { data, SearchFunction } = this.props;
    if (!e.target.value) {
      this.setState({ showData: data, searchValue: e.target.value });
    } else {
      const showData = (data || []).filter(val =>
        (val?.value || '').toLowerCase().includes(e.target.value.toLowerCase()),
      );
      this.setState({ showData, searchValue: e.target.value });
    }
    if (typeof SearchFunction === 'function') {
      SearchFunction(e.target.value);
    }
  };

  handleReset = () => {
    typeof this.props.handleReset === 'function' && this.props.handleReset();
  };

  handleOpenModal = data => () => {
    this.setState({
      showModal: true,
      name: data.value,
      accountData: { ...data },
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      name: '',
      accountData: {},
    });
  };

  render() {
    const { expanded, search, accountData, showModal, name, searchValue } = this.state;
    let { showData } = this.state;
    const {
      title,
      type,
      isCost = false,
      isSearch = true,
      data = [],
      showCost = true,
      loading = false,
      noCount = false,
      configCatAzure = false,
      disabled = false,
      billing_currency = 'USD',
      isMultipleCurrency = false,
      isCostControl = false,
      isNotOrdered = false,
      isSensitiveInfo = false,
      orderByConfig = null,
    } = this.props;
    const condition = expanded.includes(type);
    const notCount = showData.some(item => item.count === undefined);
    if (isCost) {
      showData = sortBy(showData, 'cost').reverse();
    } else if (!notCount) {
      showData = sortBy(showData, 'count').reverse();
    } else {
      showData = sortBy(showData, 'value');
    }
    showData = !isNotOrdered ? orderBy(showData, ['checked'], 'desc') : showData;
    const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

    const renderCost = row => {
      if (noCount) return null;
      if (!showCost) return null;
      if (isCost) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {` (${
              isMultipleCurrency && isCostControl
                ? formatCurrencyByCode(row.cost, billing_currency)
                : currencyFormat(row.cost)
            })`}
          </span>
        );
      }
      // records appear enabled but have zero counts so it looks inconsistent
      // if (!row.fixPermission && row.count) return `(${row.count})`;
      if (row.count) return `(${row.count})`;
      return '(0)';
    };

    return (
      <div className="np-filtersBlock">
        <div className="np-filtersHead">
          {title ? title : ''}

          {data?.length || searchValue ? (
            <span id="data-length">
              <Tippy content={`${condition ? 'Collapse' : 'Expand'}`} placement="left">
                <Anchor id={'collapse-' + type} onClick={this.onExtendToggle(type)}>
                  <i className="np-svgIcon">{condition ? <FilterCollapseIcon /> : <FilterExpandIcon />}</i>
                </Anchor>
              </Tippy>
              {isSearch && !disabled && (
                <Tippy content={`${search.includes(type) ? 'Hide' : 'Show'} search`} placement="left">
                  <Anchor id={'searchTitle-' + type} onClick={this.onSearchToggle(type)}>
                    <SearchIcon />
                    &nbsp;
                  </Anchor>
                </Tippy>
              )}
            </span>
          ) : null}
        </div>

        {searchValue || search.includes(type) ? (
          <div className={`np-filter search ${search.includes(type) && 'show'}`}>
            <div className="np-input-icon">
              <input
                id={'filterSearch-' + type}
                type="text"
                placeholder={`Search ${type} ..`}
                className="np-input search-input-filter"
                autoComplete="off"
                onChange={this.handleFilterSearch}
                onFocus={this.handleReset}
                value={searchValue}
              />
              <SearchIcon />
            </div>
          </div>
        ) : null}
        {loading ? (
          <div id="loading">Loading...</div>
        ) : data?.length && showData?.length ? (
          <>
            <div id="np-filterList" className="np-filterList">
              {condition &&
                (orderByConfig ? orderBy(showData, orderByConfig?.field, orderByConfig?.type) : showData).map(
                  (row, rowIndex) =>
                    row.value &&
                    row.value !== 'None' &&
                    ((type !== 'account' && !notCount) || type === 'account' || notCount || isCost) && (
                      <div
                        id={'np-filter' + rowIndex}
                        className={classNames({
                          'np-filter': true,
                          'np-filter-azure': configCatAzure && row.cloudPlatform === 'azure',
                          'np-filter-aws': configCatAzure && row.cloudPlatform === 'aws',
                          disabled:
                            type !== 'cloudPlatform'
                              ? isCost
                                ? row.cost === 0 || disabled
                                : row.count === 0 || disabled
                              : row.count === 0 || disabled,
                          'permissions-issue': row.fixPermission,
                        })}
                        key={configCatAzure ? `${row.cloudPlatform}::${row.id}` : row.id}
                      >
                        <div className="np-check">
                          <input
                            id={`${type}-np-check-${rowIndex}`}
                            type="checkbox"
                            checked={Boolean(row.checked)}
                            data-checkid={row.id}
                            onChange={ev => (configCatAzure ? this.onChangeRow(ev, row) : this.onChange(ev, row.value))}
                          />
                          <label className="np-check-label" htmlFor={`${type}-np-check-${rowIndex}`}>
                            <EmptySvg />
                          </label>
                        </div>
                        {/*<div className="permission-icon">
                        <RuleIcon />
                      </div>*/}
                        <label
                          className={`np-filterLabel ${isDemoMode && isSensitiveInfo ? 'demo-mode-black' : ''}`}
                          htmlFor={`${title}-np-check-${row.id}`}
                        >
                          {row.value} {renderCost(row)}
                        </label>
                        {type === 'account' && (
                          <Tippy content="Rename cloud account" placement="left">
                            <Anchor id={'pencilIcon-' + type} className="edit-name" onClick={this.handleOpenModal(row)}>
                              <PencilIcon />
                            </Anchor>
                          </Tippy>
                        )}

                        {row.fixPermission && (
                          <div id="fix">
                            <Anchor className="fix" title="Fix Permission" onClick={this.handleFixPermission(row.id)}>
                              Fix Permission
                            </Anchor>

                            <span className="permission-message">
                              We detected this account in consolidated billing. &nbsp;Give nOps read-only permission to
                              access this account.
                            </span>
                          </div>
                        )}
                      </div>
                    ),
                )}
            </div>
          </>
        ) : (
          <div id="none">None</div>
        )}

        {showModal ? (
          <RenameAWSAccount
            closeModal={this.handleCloseModal}
            data={{
              name: name,
              id: accountData.id,
            }}
            configCatAzure={configCatAzure}
          />
        ) : null}
      </div>
    );
  }
}

export default Filter;
